.conteneurChat {
    display: flex;
    flex-direction: column;
    height: 100vh; 
    background-color: white;
    overflow: hidden;
}
*{
    padding: 0;
    margin: 0;
}

