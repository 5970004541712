.outer__input {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 1rem;
  background-color: #f3f6fb;
  color: #3c4043;
}

.input__message {
  flex: 2;
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.sendIcon {
  font-size: medium;
}
.outer__input .empty {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: small;
  color: gray;
}
.outer__input .empty p {
  position: relative;
  text-align: center;
}

/* --------------   Media queries   ---------------- */
@media (max-width: 1024px) {
  .empty p {
    font-size: 0.7rem;
  }
}
@media (max-width: 768px) {
  .empty p {
    font-size: 0.6rem;
    margin-top: 0.2rem;
  }
}
@media (max-width: 500px) {
  .outer__input {
    flex-direction: column-reverse;
  }
  .input__message,
  .outer__input {
    margin: 0;
    padding: 0 0 1rem 0;
  }
  .input__message {
    padding-bottom: 0;
    padding-top: 0.5rem;
    padding-left: 1rem;
  }
  .custom-label {
    font-size: 5px;
  }
  .empty p {
    margin-top: 0.2rem;
  }
}
